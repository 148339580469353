'use strict'

export default function soda() {
    
    const LOAD_COUNT = 25;
    
    // const makeName = function (name) {
    //     return name
    //     .split('-')
    //     .map(e => e.charAt(0).toUpperCase() + e.slice(1))
    //     .join(' ');
    // }
    let artists = [
        'alan-sondheim',
        'arvin-ombika',
        'danielle-imara',
        'darja-linder',
        'enne',
        'erik-herrera',
        'heather-robinson',
        'janna-yotte',
        'julia-kannewischer',
        'kathrin-bergenthal',
        'kim-engelen',
        'maxime-deria',
        'mez-breeze',
        'oezlem-sariyildiz',
        'ritam-talukdar',
        'roel-heremans',
        'strange-strangers',
        'sziklak-virtual-gallery-team',
        'veronika-rudez',
        // 2nd upload
        'adelina-ivan',
        // 'alexey-salmanov',
        'ana-jovanovska',
        'anica-seidel',
        'clara-abi-nader',
        'dalesia-cozorici',
        'david-leshem',
        'doris-graf',
        'francisca-tambasco',
        'frans-van-lent',
        // 'georgios-varousos',
        'gisela-ferreira',
        'hans-peter-kirsch',
        'harry-mcmahon',
        'iris-perez-romero',
        'isabelle-rein',
        'jessica-moritz',
        'joe-banks',
        'jonah-brucker-cohen',
        'kim-roberts',
        'konrad-schaefer',
        'mario-maurer',
        'negar-aghaalitari',
        'nerea-lekuona',
        'nils-addink',
        'oriada-dajko',
        'patricia-harsany',
        'sabina-jacobsson',
        'sigfredo-gonzalez',
        'silas-neumann',
        'topp-dubio',
        'veronique-verdet',
        'volker-schuetz',
        'xan-padron',
        'xiaofeng-chen',
        'zsolt-mesterhazy',
        // 3rd upload
        'giuseppe-mongiello',
        'ebba-jahn',
        'auditiv-vokal-dresden',
        'anneli-di-francis',
        
        'albert-herbig',
        'georgios-varoutsos',
        'holle-hoffmann',
        'jawdat-ahmet',
        'jeremy-gluck',
        'luise-talbot',
        'markus-busch',
        'marte-kiessling',
        'nooshin-ramezani-moghadam',
        'novian-arif',
        'oihanna-marco',
        'riitta-oittinen',
        'sarah-scholz',
        'sean-corcoran',
        'soha-asadi',
        'thomas-chung',
        'timo-ullmann',
        'val-lee',
        'omar-edwards',
        'hannah-schaefer'
    ]
    
    function updateIdx() {
        let idx_prev = localStorage.getItem('alan-sondheim');
        let idx = idx_prev == null ? 1 : !(++idx_prev < artists.length) ? 0 : idx_prev;
        console.log("teaser pointer: " + idx);
        localStorage.setItem('alan-sondheim', idx);
        return idx;
    }
    
    function orderArtistByN(artists, n) {
        let res = [];
        for (let i = 0; i < artists.length; i++) {
            let idx = i + n;
            let artist = artists[i];
            let len = artists.length;
            if (idx < len) {
                res[idx] = artist;
            } else {
                res[idx - len] = artist;
            }
        }
        return res;
    }
    
    function render(artistImgs) {
        let artistsHtml = artistImgs.map(artist => {
            console.log("create teaser for " + artist);
            return '<section class="block preview" onclick="location.href=&quot;artists/' + artist + '.html&quot;" style="background-image: url(&quot;/artists/img/' + artist + '-1.png&quot;);"><p class"center-text"></p></section>'
        }).join('<!---->')
        
        let sectionContainer = document.querySelector("#artist-teaser");
        sectionContainer.innerHTML += '<!---->' + artistsHtml;
    }
    
    return {
        // paginate: function(catalog) {
        //     console.log('paginate ' + window.location.href);
        //     let idx = findIdx();
        //     let prev = createPreviousElement(idx);
        //     let next = createNextElement(idx);
        //     let pagination = document.querySelector('.pagination')
        //     pagination.appendChild(prev);
        //     pagination.appendChild(next);
            
        //     function findIdx() {
        //         let split = window.location.href.split('/');
        //         let key = '/' + split[split.length - 2] + '/' + split[split.length - 1];
        //         for (let index = 0; index < catalog.length; index++) {
        //             const element = catalog[index];
        //             if (key == element.url) {
        //                 return index;
        //             }
        //         }
        //     }

        //     function createPreviousElement(idx) {
        //         let prev = document.createElement('div');
        //         let txt = document.createElement('span');
        //         txt.innerText = "PREVIOUS";

        //         if (idx == 0) {
        //             prev.style.display = 'none';
        //         } else {
        //             let url = catalog[idx - 1].url;
        //             let hyperlink = document.createElement('a');
        //             hyperlink.setAttribute('href', url);
        //             // hyperlink.innerText = "PREVIOUS"
        //             hyperlink.className = 'icon-left-circle';
        //             prev.appendChild(hyperlink)
        //             prev.appendChild(txt);
        //         }
        //         // prev.className = 'icon-left-circle';
        //         return prev
        //     }

        //     function createNextElement(idx) {
        //         let next = document.createElement('div');
        //         let txt = document.createElement('span');
        //         txt.innerText = "NEXT";
        //         next.appendChild(txt);
        //         if (idx == catalog.length - 1) {
        //             next.style.display = 'none';
        //         } else {
        //             let url = catalog[idx + 1].url
        //             let hyperlink = document.createElement('a');
        //             hyperlink.setAttribute('href', url);
        //             // hyperlink.innerText = "NEXT"
        //             hyperlink.className = 'icon-right-circle';
        //             next.appendChild(hyperlink)
        //         }
        //         // next.className = 'icon-right-circle';
        //         return next
        //     }
        // },
        loadImages: function() {
            console.log("my fresh soda start page yes!")
            console.log("amount artists: " + artists.length);
            let idx = updateIdx();
            let orderedArtists = orderArtistByN(artists, idx);
            render(orderedArtists);
        },
        loadImagesLazily: function() {
            console.log("soda landing page using handcrafted lazy image loading strategy")
            console.log("amount artists: " + artists.length);
            let idx = updateIdx();
            let orderedArtists = orderArtistByN(artists, idx);
            let io = new IntersectionObserver(
                entries => {
                    if (entries[0].isIntersecting) {
                        let images = nextImagesToLoad(orderedArtists);
                        console.log('lazy loading more images: ' + images);
                        render(images);
                        if (images.length >= LOAD_COUNT) {
                            setIntersectionSentinel();
                        }
                        io.unobserve(entries[0].target);
                    }
                });
                
                let images = nextImagesToLoad(orderedArtists);
                render(images);
                if (images.length >= LOAD_COUNT) {
                    setIntersectionSentinel();
                }
                
                function setIntersectionSentinel() {
                    let previews = document.querySelectorAll('.preview');
                    let sentinel = previews[previews.length - 7];
                    io.observe(sentinel);
                }
                
                function nextImagesToLoad(imgs) {
                    return imgs.splice(0, LOAD_COUNT);
                }            
            }
        }
        
        // if (document.querySelector('#artist-teaser')) {
        
        // }
        
    }
    
    
