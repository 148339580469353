'use strict';

import css from '../css/css.css';
import scss from '../css/sass.scss';
import main from '../css/main.css';
import normalize from '../css/normalize.css';
import hazelscss from '../css/hazel.scss';
import sodascss from '../css/soda.scss';
import Particles from './entities/particles';
import soda from './soda'
// import catalog from './catalog'

window.h5 = {
    isPc: function () {
        var userAgentInfo = navigator.userAgent;
        var Agents = new Array('Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod');
        var flag = true;
        for (var v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }
        }
        return flag;
    },
    rootResize: function () {
        var wFsize;
        
        //iphone6/6s/7/8 orientation=portrait screen.width=750px screen.height=1334px / WeChat window.innerWidth=750px window.innerHeight=1206px 
        var wWidth = document.documentElement.clientWidth || window.innerWidth;
        var wHeight = document.documentElement.clientHeight || window.innerHeight;
        
        if (wWidth > wHeight) {
            wFsize = wHeight / 750 * 100;
        } else {
            wFsize = wWidth / 750 * 100;
        }
        document.getElementsByTagName('html')[0].style.fontSize = wFsize + 'px';
    },
    eventInit: function () {
        var that = this;
        document.addEventListener('touchstart', function (e) { }, { passive: false });
        document.addEventListener('touchmove', function (e) {
            e.preventDefault();
        }, { passive: false });
        return that;
    },
    cssInit: function () {
        var that = this;
        var noChangeCountToEnd = 100,
        noEndTimeout = 1000;
        that.rootResize();
        
        function orientationChanged() {
            const timeout = 120;
            return new window.Promise(function (resolve) {
                var go = (i, height0) => {
                    window.innerHeight != height0 || i >= timeout ?
                    resolve() :
                    window.requestAnimationFrame(() => go(i + 1, height0));
                };
                go(0, window.innerHeight);
            });
        }
        
        window.addEventListener('onorientationchange' in window ? 'orientationchange' : 'resize', function () {
            /* using requestAnimationFrame */
            orientationChanged().then(function () {
                // Profit
                that.rootResize();
            });
            
        });
        
        return that;
    },
    init: function () {
        var that = this;
        // that.cssInit().eventInit();
    }
};

const isPathStartingWith = function(name) {
    let part = window.location.pathname.split('/')[1];
    return part === name || part.startsWith(name);
}

// const catalog = require('./catalog.json');
window.onload = function () {
    const sodajs = soda();
    if (isPathStartingWith('index.html') || isPathStartingWith('')) {
        if (document.querySelector('#artist-teaser')) {
            if ("IntersectionObserver" in window) {
                sodajs.loadImagesLazily();
            } else {
                sodajs.loadImages();
            }
        }
    }

    // if (isPathStartingWith('artists')) {
    //     sodajs.paginate(catalog());
    // }
    
    let ignoreBubbles = ['gdpr','impressum','sponsors', 'donation'].some(isPathStartingWith);
    if (!ignoreBubbles){
        Particles();
    }
    window.h5.init();
};