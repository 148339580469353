'use strict'


import palette from './colors'

export default function Particles() {

    console.log("loading particles");
    
    var totalHeight = document.querySelector('.container').offsetHeight;
    var totalWidth = document.querySelector('.container').offsetWidth;
    var VELOCITY = 1;
    // var PARTICLES = 100;
    var PARTICLES = getBubbleAmount(totalHeight, totalWidth);
    console.log('let fly ' +  PARTICLES + ' bubbles');

    var mouse = { x: 0, y: 0 };
    var particles = [];

    let palettePosition = randomIntFromInterval(0, palette().length - 1);
    let colors = palette()[palettePosition];
    console.log('colors: ' + colors + ' picked at position ' + palettePosition);

    var canvas = document.getElementById('projector');
    var context;


    function getBubbleAmount(height, width) {
        let n = (height + width) * 0.04;
        return Math.round(n);
    }

    console.log("total height: " + totalHeight);

    if (canvas && canvas.getContext) {
        context = canvas.getContext('2d');

        for (var i = 0; i < PARTICLES; i++) {
            particles.push({
                x: Math.random() * window.innerWidth,
                y: Math.random() * totalHeight,
                vx: ((Math.random() * (VELOCITY * 2)) - VELOCITY),
                vy: ((Math.random() * (VELOCITY * 2)) - VELOCITY),
                size: 1 + Math.random() * 3,
                color: colors[Math.floor(Math.random() * colors.length)]
            });
        }

        Initialize();
    }

    function Initialize() {
        // canvas.addEventListener('mousemove', MouseMove, false);
        // window.addEventListener('mousedown', MouseDown, false);
        window.addEventListener('resize', ResizeCanvas, false);
        // setInterval( TimeUpdate, 40 );
        setInterval(TimeUpdate, 40);

        // TimeUpdate(null);
        ResizeCanvas();
    }

    function randomIntFromInterval(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    function TimeUpdate(e) {

        context.clearRect(0, 0, window.innerWidth, totalHeight);

        var len = particles.length;
        var particle;

        for (var i = 0; i < len; i++) {
            particle = particles[i];

            if (!particle.frozen) {
                particle.x += particle.vx;
                particle.y += particle.vy;

                if (particle.x > window.innerWidth) {
                    particle.vx = -VELOCITY - randomIntFromInterval(1, 2); // Math.random();
                }
                else if (particle.x < 0) {
                    particle.vx = VELOCITY + randomIntFromInterval(1, 2); //Math.random();
                }
                else {
                    particle.vx *= 1; //+ (randomIntFromInterval(1, 2) * 0.005) // (Math.random() * 0.005);
                }
                
                if (particle.y > totalHeight) {
                    particle.vy = -VELOCITY - randomIntFromInterval(1, 2); //Math.random();
                }
                else if (particle.y < 0) {
                    particle.vy = VELOCITY + randomIntFromInterval(1, 2); //Math.random();
                }
                else {
                    particle.vy *= 1; // + (randomIntFromInterval(1, 2) * 0.005); //(Math.random() * 0.005);
                }

                var distanceFactor = DistanceBetween(mouse, particle);
                distanceFactor = Math.max(Math.min(15 - (distanceFactor / 10), 10), 1);

                particle.currentSize = particle.size * distanceFactor;
            }

            context.fillStyle = particle.color;
            context.beginPath();
            context.arc(particle.x, particle.y, particle.currentSize, 0, Math.PI * 2, true);
            context.closePath();
            context.fill();

        }
    }

    function MouseMove(e) {
        mouse.x = e.layerX;
        mouse.y = e.layerY;
    }

    function MouseDown(e) {
        var len = particles.length;

        var closestIndex = 0;
        var closestDistance = 1000;

        for (var i = 0; i < len; i++) {
            var thisDistance = DistanceBetween(particles[i], mouse);

            if (thisDistance < closestDistance) {
                closestDistance = thisDistance;
                closestIndex = i;
            }

        }

        if (closestDistance < particles[closestIndex].currentSize) {
            particles[closestIndex].frozen = true;
        }
    }

    function ResizeCanvas(e) {
        totalHeight = document.getElementsByClassName("container")[0].offsetHeight;
        canvas.width = window.innerWidth;
        canvas.height = totalHeight;
    }

    function DistanceBetween(p1, p2) {
        var dx = p2.x - p1.x;
        var dy = p2.y - p1.y;
        return Math.sqrt(dx * dx + dy * dy);
    }
}